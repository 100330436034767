import React, { Component, SyntheticEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import { injector } from 'Injector';
import { TeachingPathService, TEACHING_PATH_SERVICE } from 'teachingPath/service';
import { Assignment, LenguajesC, Translations, Grade, Subject, GenericGrepItem } from 'assignment/Assignment';
import { deadlineDateFormat, thirdLevel, LANGUAGESC, LANGUAGESD  } from 'utils/constants';

import closeimg from 'assets/images/close-button.svg';
import gradeImg from 'assets/images/grade.svg';
import goalsImg from 'assets/images/goals.svg';
import steps from 'assets/images/teaching-path.svg';
import article from 'assets/images/article.svg';
import person from 'assets/images/person.svg';
import date from 'assets/images/date.svg';
import subject from 'assets/images/tags.svg';
import coreElement from 'assets/images/core.svg';
import multiSubject from 'assets/images/cogs.svg';
import sourceImg from 'assets/images/voice.svg';
import lang from 'assets/images/lang.svg';
import editImg from 'assets/images/edit-tp.svg';
import deleteImg from 'assets/images/trash-tp.svg';

import './TeachinPathContent.scss';

interface Props {
  id: number;
  localeId: number;
  title: string;
  description: string;
  onClose: () => void;
}

interface State {
  modalOpen: boolean;
  modalPreview: boolean;
  modalFunction: boolean;
  numberOfStepsMin: number;
  numberOfStepsMax: number;
  numberOfArticles: number;
  author: string;
  authorRole: string;
  isPrivate: boolean;
  createdAt: string;
  isTranslations: boolean;
  translations: Array<Translations>;
  modalInsideView: boolean;
  modalInsidePreview: boolean;
  modalInsideEdit: boolean;
  hasGuidance: boolean;
  modalInsideEditTeacher: boolean;
  originalLocaleId: number;
  grades: Array<Grade>;
  subjects: Array<Subject>;
  coreElements: Array<GenericGrepItem>;
  topics: Array<GenericGrepItem>;
  sources: Array<GenericGrepItem>;
  goals: Array<GenericGrepItem>;
}
class TPContent extends Component<Props, State> {
  private teachingPathService: TeachingPathService = injector.get(TEACHING_PATH_SERVICE);
  public state = {
    modalOpen: false,
    modalPreview: false,
    modalFunction: false,
    numberOfStepsMin: 0,
    numberOfStepsMax: 0,
    numberOfArticles: 0,
    author: '',
    authorRole: '',
    isPrivate: false,
    createdAt: '',
    isTranslations: false,
    translations: [],
    modalInsideView: false,
    modalInsidePreview: false,
    modalInsideEdit: false,
    hasGuidance: false,
    modalInsideEditTeacher: false,
    originalLocaleId: 0,
    grades: [],
    subjects: [],
    coreElements: [],
    topics: [],
    sources: [],
    goals: []
  };
  public renderPublishDate = (createdAt: string) =>
  (
    <div className="partsInfo">
      <img src={date} alt="date" />
      {`${moment(createdAt).format(deadlineDateFormat)}`}
    </div>
  )
  public renderLangs = () => {
    const mytranslations = this.state.translations as Array<Translations>;
    const arrayLenguajes : Array<LenguajesC> = [];
    if (mytranslations) {
      mytranslations.forEach((t) => {
        if (Boolean(t.value)) {
          const id = t.id;
          const names = (LANGUAGESD.find(x => x.id === id)) ? LANGUAGESD.find(x => x.id === id)!.name : '';
          const namesingle = (LANGUAGESD.find(x => x.id === id)) ? LANGUAGESD.find(x => x.id === id)!.name : '';
          const LANG = { id: t.id , name: names, code: namesingle };
          arrayLenguajes.push(LANG);
        }
      });
    }
    const renderPreviewLanguage = (language: { name: string, id: number }) => (
      <li
        key={language.id}
      >
        {language.name}
      </li>
    );
    return (
      <div className="partsInfo partsInfoList">
        <img src={lang} alt="langs" />
        <ul>
          {arrayLenguajes.map(renderPreviewLanguage)}
        </ul>
      </div>
    );
  }
  public renderNumberArraySubject = (item: GenericGrepItem) => (
    <li className="item">
      {item.description}
    </li>
  )
  public emptyData = () => (
    <li className="item">
      {intl.get('no data')}
    </li>
  )
  public renderGrepSubjectsArray = () => {
    const { subjects } = this.state;
    const isData = (subjects.length === 0) ? false : true;
    return (
      <div className="entityInfoBlock">
        <div className="imageGrep">
          <img className="imgInfo" src={subject} />
        </div>
        <div>
          <div className="title">{intl.get('preview.teaching_path.grep.subjects')}</div>
          <div>
            <ul className="listItem">
              {!isData && this.emptyData()}
              {isData && subjects.map(this.renderNumberArraySubject)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  public renderStringArray = (item: GenericGrepItem) => (
    <li className="item">
      {item.description}
    </li>
  )
  public renderGrepCoreElements = () => {
    const { coreElements } = this.state;
    const isData = (coreElements.length === 0) ? false : true;
    return (
      <div className="entityInfoBlock">
        <div className="imageGrep">
          <img className="imgInfo" src={coreElement} />
        </div>
        <div>
          <div className="title">{intl.get('preview.teaching_path.grep.core_elements')}</div>
          <div>
            <ul className="listItem">
              {!isData && this.emptyData()}
              {isData && coreElements.map(this.renderStringArray)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  public renderGrepMultiSubjects = () => {
    const { topics } = this.state;
    const isData = (topics.length === 0) ? false : true;
    return (
      <div className="entityInfoBlock">
        <div className="imageGrep">
          <img className="imgInfo" src={multiSubject} />
        </div>
        <div>
          <div className="title">{intl.get('preview.teaching_path.grep.multidisciplinary_subjects')}</div>
          <div>
            <ul className="listItem">
              {!isData && this.emptyData()}
              {isData && topics.map(this.renderStringArray)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  public renderGrepSources = () => {
    const { sources } = this.state;
    const isData = (sources.length === 0) ? false : true;
    return (
      <div className="entityInfoBlock">
        <div className="imageGrep">
          <img className="imgInfo" src={sourceImg} />
        </div>
        <div>
          <div className="title">{intl.get('preview.teaching_path.grep.sources')}</div>
          <div>
            <ul className="listItem">
              {!isData && this.emptyData()}
              {isData && sources.map(this.renderStringArray)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  public renderListGoal = (item: GenericGrepItem) => (
    <li className="goalData">
      <div className="goalData__grade">{item.gradeDesc}</div>
      <div className="goalData__subject">{item.subjectDesc}</div>
      <div className="goalData__name">{item.description}</div>
    </li>
  )
  public renderGrepEducationalGoals = () => {
    const { goals } = this.state;
    const isData = (goals.length === 0) ? false : true;
    return (
      <>
        <div className="entityInfoBlockExpanded">
          <div className="imageGrep">
            <img className="imgInfo" src={goalsImg} />
          </div>
          <div className="title">{intl.get('preview.teaching_path.grep.educational_goals')}</div>
        </div>
        <div className="flexContainerExpanded">
          <ul className="listItem">
            {!isData && this.emptyData()}
            {isData && goals.map(this.renderListGoal)}
          </ul>
        </div>
      </>
    );
  }
  public changeOpenpreview = () => {
    if (this.state.modalPreview) {
      this.setState({ modalPreview: false });
    } else {
      this.setState({ modalPreview: true });
    }
  }
  public setViewButtonByLenguaje = (idlenguaje : number) => {
    const { id } = this.props;
    const url: URL = new URL(window.location.href);
    const urlForEditing: string = `${url.origin}/teaching-path/preview/${id!}?localeId=${idlenguaje}`;
    window.open(urlForEditing);
  }
  public setPreViewButtonByLenguaje = (idlenguaje : number) => {
    const { id } = this.props;
    const url: URL = new URL(window.location.href);
    const urlForEditing: string = `${url.origin}/teaching-path/preview/${id!}?localeId=${idlenguaje}`;
    window.open(urlForEditing);
  }
  public openInNewTabTeacherGuidance = (idlenguaje : number) => {
    const { id } = this.props;
    const url: URL = new URL(window.location.href);
    const urlForEditing: string = `${url.origin}/teaching-path/preview/${id!}/?localeId=${idlenguaje}&tg=true`;
    window.open(urlForEditing);
  }
  public insidePreviewchangeOpenFunction = () => {
    if (this.state.modalInsidePreview) {
      this.setState({ modalInsidePreview: false });
    } else {
      this.setState({ modalInsidePreview: true });
    }
  }
  public insidechangeOpenFunction = () => {
    if (this.state.modalInsideView) {
      this.setState({ modalInsideView: false });
    } else {
      this.setState({ modalInsideView: true });
    }
  }
  public renderTeacherGuidanceButtonList = (guidanceString: string, idlenguaje : number) =>
    (
      <li>
        <a href="javascript:void(0)" className="linkOpenSite LinkRollback" onClick={() => this.openInNewTabTeacherGuidance(idlenguaje)}>
          {guidanceString}
        </a>
      </li>
    )
  public contentPreview = () => {
    const viewText = intl.get('preview.teaching_path.buttons.view');
    const guidanceText = intl.get('preview.assignment.buttons.teacher_guidance');
    const viewStudentText = intl.get('preview.teaching_path.buttons.viewstudent');
    const { localeId } = this.props;
    const mytranslations = this.state.translations as Array<Translations>;
    const arrayLenguajes : Array<LenguajesC> = [];
    if (mytranslations) {
      mytranslations.forEach((t) => {
        if (Boolean(t.value)) {
          const id = t.id;
          const names = (LANGUAGESC.find(x => x.id === id)) ? LANGUAGESC.find(x => x.id === id)!.name : '';
          const namesingle = (LANGUAGESC.find(x => x.id === id)) ? LANGUAGESC.find(x => x.id === id)!.name : '';
          const LANG = { id: t.id , name: names, code: namesingle };
          arrayLenguajes.push(LANG);
        }
      });
    }
    const renderLanguage = (language: { name: string, id: number }) => (
      <li
        className="itemList"
        key={language.id}
      >
        <a
          href="javascript:void(0)"
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.setViewButtonByLenguaje(language.id)}
        >
          {language.name}
        </a>
      </li>
    );
    const renderPreviewLanguage = (language: { name: string, id: number }) => (
      <li
        className="itemList"
        key={language.id}
      >
        <a
          href="javascript:void(0)"
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.setPreViewButtonByLenguaje(language.id)}
        >
          {language.name}
        </a>
      </li>
    );
    const simpleClassView = (this.state.modalInsideView) ? 'modalContentInside active' : 'modalContentInside';
    const simpleClassPreview = (this.state.modalInsidePreview) ? 'modalContentInside active' : 'modalContentInside';
    return (
      <div className="modalContent">
        <ul>
          {arrayLenguajes.map(renderLanguage)}
          {this.state.hasGuidance && this.renderTeacherGuidanceButtonList(guidanceText, localeId)}
        </ul>
      </div>
    );
  }
  public handleClickOutside = () => this.props.onClose();
  public close = () => this.props.onClose();
  public async componentDidMount() {
    const { id } = this.props;
    const tpservice = await this.teachingPathService.getTeachingPathById(id);
    this.setState({
      modalOpen: true,
      numberOfStepsMax : tpservice.numberOfSteps.max!,
      numberOfStepsMin : tpservice.numberOfSteps.min!,
      numberOfArticles : tpservice.numberOfArticles!,
      author: tpservice.author!,
      authorRole: tpservice.authorRole!,
      isPrivate: tpservice.isPrivate,
      createdAt: tpservice.createdAt,
      isTranslations: tpservice.isTranslations!,
      translations: tpservice.translations!,
      hasGuidance: tpservice.hasGuidance,
      originalLocaleId: tpservice.originalLocaleId!,
      subjects: tpservice.subjects,
      grades: tpservice.grades,
      coreElements: tpservice.coreElementItems,
      topics: tpservice.multiSubjectItems,
      sources: tpservice.sourceItems,
      goals: tpservice.goalsItems
    });
  }
  public render() {
    const {
      title,
      description
    } = this.props;
    const openPreview = (this.state.modalPreview) ? 'modalToggle active' : 'modalToggle';
    const openModal = (this.state.modalOpen) ? 'previewModalInfoSearch' : 'previewModalInfoSearch hidden';
    return (
      <div className={openModal}>
        <div className="previewModalInfoSearch__background" onClick={this.close} />
        <div className="previewModalInfoSearch__content">
          <div className="contentContainer">
            <div className="NewheaderPanel">
                <div className="headerButtons">
                  <div className="previewButtons">
                    <a href="javascript:void(0)" className={openPreview} onClick={this.changeOpenpreview}>{intl.get('new assignment.Preview')}</a>
                    {this.state.modalPreview && this.contentPreview()}
                  </div>
                </div>
            </div>
            <div className="NewTitleHeader">
                <h2>{title}</h2>
            </div>
            <div className="entityInfo">
                <div className="partsInfo">
                  <img src={steps} alt="question" />
                  {this.state.numberOfStepsMin === this.state.numberOfStepsMax ? `${this.state.numberOfStepsMin}` : `${this.state.numberOfStepsMin}-${this.state.numberOfStepsMax}`} {`${intl.get('preview.teaching_path.headers.steps')}`}
                </div>
                <div className="partsInfo">
                  <img src={article} alt="question" />
                  {this.state.numberOfArticles ? this.state.numberOfArticles : intl.get('preview.teaching_path.headers.no')} {`${intl.get('preview.teaching_path.headers.articles')}`}
                </div>
                <div className="partsInfo partsInfoAutor">
                  <img src={person} alt="question" />
                  <p>{`${intl.get('preview.teaching_path.headers.by')}`} {this.state.author ? this.state.author : ''}</p>
                </div>
                {this.state.createdAt && this.renderPublishDate(this.state.createdAt)}
                {this.state.translations.length > 0 && this.renderLangs()}
            </div>
            <div className="entityDescription">
                <div className="partsInfoDescription">
                  {description ? description : ''}
                </div>
            </div>
            <div className="summary">
              {this.renderGrepSubjectsArray()}
              {this.renderGrepCoreElements()}
              {this.renderGrepMultiSubjects()}
              {this.renderGrepSources()}
              {this.renderGrepEducationalGoals()}
            </div>
            <div className="footerButtons" />
          </div>
        </div>
      </div>
    );
  }
}
const TPComponent = onClickOutside(TPContent);
export { TPComponent as TPContent };
