import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import isNull from 'lodash/isNull';
import { DraftTeachingPath, EditableTeachingPathNode } from 'teachingPath/teachingPathDraft/TeachingPathDraft';
import { EditTeachingPathStore } from 'teachingPath/view/EditTeachingPath/EditTeachingPathStore';
import { DraftAssignment } from 'assignment/assignmentDraft/AssignmentDraft';
import { NewAssignmentStore } from 'assignment/view/NewAssignment/NewAssignmentStore';
import { CurrentQuestionaryStore } from 'assignment/view/CurrentAssignmentPage/CurrentQuestionaryStore';
import { CreateButton } from 'components/common/CreateButton/CreateButton';
import { QuestionaryTeachingPathStore, SubmitNodeType } from 'teachingPath/questionaryTeachingPath/questionaryTeachingPathStore';

import subject from 'assets/images/tags.svg';
import coreElement from 'assets/images/core.svg';
import multiSubject from 'assets/images/cogs.svg';
import source from 'assets/images/voice.svg';
import goalsIcon from 'assets/images/goals.svg';
import './DetailsModal.scss';
import { injector } from 'Injector';
import { TeachingPathService, TEACHING_PATH_SERVICE } from 'teachingPath/service';
import { GoalsData, Subject } from 'assignment/Assignment';
import { TeachingPath } from 'teachingPath/TeachingPath';
import { ItemContentType } from 'teachingPath/view/EditTeachingPath/ItemContentTypeContext';
const NumberTen = 10;
interface Props {
  isTeachingPath?: boolean;
  isAssignment?: boolean;
  editTeachingPathStore?: EditTeachingPathStore;
  currentEntityTeachingPath?: DraftTeachingPath;
  newAssignmentStore?: NewAssignmentStore;
  drafAssignment?: DraftAssignment;
  currentQuestionaryStore?: CurrentQuestionaryStore;
  isOpenClick: boolean;
  questionaryTeachingPathStore?: QuestionaryTeachingPathStore;
  tpID?: number;
}

interface GoalsAux {
  gradeDesc: string | null | undefined;
  description: string;
}

interface GoalsInterface {
  subjectId: number;
  subjectDesc: string;
  goalsArray: Array<GoalsAux>;
}

interface State {
  isOpen: boolean;
  subjects: Array<string>;
  coreElements: Array<string>;
  multiSubjects: Array<string>;
  sources: Array<string>;
  goals: Array<GoalsAux>;
  entityTeachingPath: TeachingPath | undefined;
}

@inject('questionaryTeachingPathStore')
@inject('editTeachingPathStore')
@inject('newAssignmentStore')
@observer

export class DetailsModal extends Component<Props, State> {
  private teachingPathService: TeachingPathService = injector.get(TEACHING_PATH_SERVICE);
  public state = {
    isOpen: false,
    subjects: [],
    coreElements: [],
    multiSubjects: [],
    sources: [],
    goals: [],
    entityTeachingPath: undefined,

  };

  public openModalDetail = () => {
    this.setState({ isOpen: true });
  }
  public closeModalDetail = () => {
    this.props.questionaryTeachingPathStore!.isOpenModalDetails = false;
  }
  public buttonOpenDetailsModal = () => {
    const { isOpen } = this.state;
    return (
      <CreateButton title={intl.get('generals.viewdetails')} onClick={this.openModalDetail}>
        {intl.get('generals.viewdetails')}
      </CreateButton>
    );
  }
  public subjectRender = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { subjects } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={subject} /> {intl.get('preview.teaching_path.grep.subjects')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contenTeaching!.subjects.map(item => <li key={item.id}>{item.title}</li>)}
        </ul>
      </div>
    );
  }

  public subjectRenderAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { subjects } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={subject} /> {intl.get('preview.teaching_path.grep.subjects')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contentAssignment!.subjects.map(item => <li key={item.id}>{item.title}</li>)}
        </ul>
      </div>
    );
  }

  public coreElementsRender = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { coreElements } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={coreElement} /> {intl.get('preview.teaching_path.grep.core_elements')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contenTeaching!.coreElements!.map(item => <li key={item.id}>{item.title}</li>)}
        </ul>
      </div>
    );
  }

  public coreElementsRenderAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { coreElements } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={coreElement} /> {intl.get('preview.teaching_path.grep.core_elements')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contentAssignment!.coreElements!.map(item => <li key={item.id}>{item.description}</li>)}
        </ul>
      </div>
    );
  }

  public multiSubjectsRender = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { multiSubjects } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={multiSubject} /> {intl.get('preview.teaching_path.grep.multidisciplinary_subjects')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contenTeaching!.mainTopics!.map(item => <li key={item.id}>{item.title}</li>)}
        </ul>
      </div>
    );
  }

  public multiSubjectsRenderAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { multiSubjects } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={multiSubject} /> {intl.get('preview.teaching_path.grep.multidisciplinary_subjects')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contentAssignment!.mainTopics!.map(item => <li key={item.id}>{item.description}</li>)}
        </ul>
      </div>
    );
  }

  public sourcesRender = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { sources } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={source} /> {intl.get('preview.teaching_path.grep.sources')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contenTeaching!.sources!.map(item => <li key={item.id}>{item.title}</li>)}
        </ul>
      </div>
    );
  }

  public sourcesRenderAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { sources } = this.state;
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={source} /> {intl.get('preview.teaching_path.grep.sources')}</h3>
        <ul className="modalContentTG__body__list">
          {questionaryTeachingPathStore!.contentAssignment!.sources!.map(item => <li key={item.id}>{item.description}</li>)}
        </ul>
      </div>
    );
  }

  public transformGoals = () => {
    const { questionaryTeachingPathStore } = this.props;
    const newGoalsArray: Array<GoalsInterface> = [];
    questionaryTeachingPathStore!.contenTeaching!.goals!.forEach((goal) => {
      const newGoal = {} as GoalsInterface;
      const newGoals = {} as GoalsAux;
      const goalindex = newGoalsArray.map(e => e.subjectId).indexOf(goal.subjectId);
      if (goalindex < 0) {
        newGoal.subjectId = goal.subjectId;
        newGoal.subjectDesc = goal.subjectDesc;
        newGoal.goalsArray = [];
        newGoals.gradeDesc = goal.gradeDesc;
        newGoals.description = goal.description;
        newGoal.goalsArray.push(newGoals);
        newGoalsArray.push(newGoal);
      } else {
        newGoals.gradeDesc = goal.gradeDesc;
        newGoals.description = goal.description;
        newGoalsArray[goalindex].goalsArray.push(newGoals);
      }
    });
    return newGoalsArray;
  }

  public goalsRender = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { goals } = this.state;
    let classsubjectTitle = 'subjectTitle';
    if (this.transformGoals().length > 0) {
      this.transformGoals().forEach((e) => {
        if (e.goalsArray.length > 0) {
          e.goalsArray.forEach((ei) => {
            if (ei.gradeDesc!.length > NumberTen) {
              classsubjectTitle = 'subjectTitle subjectTitleCont';
            }
          });
        }
      });
    }
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={goalsIcon} /> {intl.get('preview.teaching_path.grep.educational_goals')}</h3>
        <ul className="modalContentTG__body__listGoals">
          {/* tslint:disable-next-line:max-line-length */}
          {this.transformGoals().map(item => <li key={item.subjectId}><div className={classsubjectTitle}>{item.subjectDesc}<div className="description"><ul>{item.goalsArray.map(li => <li key={item.subjectId} className="goalsItem"><div className="goalsItem__grade">{li.gradeDesc}</div><div className="goalsItem__description">{li.description}</div></li>)}</ul></div></div></li>)}
        </ul>
      </div>
    );
  }

  public transformGoalsAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const newGoalsArray: Array<GoalsInterface> = [];
    questionaryTeachingPathStore!.contentAssignment!.goals!.forEach((goal) => {
      const newGoal = {} as GoalsInterface;
      const newGoals = {} as GoalsAux;
      const goalindex = newGoalsArray.map(e => e.subjectId).indexOf(goal.subjectId!);
      if (goalindex < 0) {
        newGoal.subjectId = goal.subjectId!;
        newGoal.subjectDesc = goal.subjectDesc!;
        newGoal.goalsArray = [];
        newGoals.gradeDesc = goal.gradeDesc;
        newGoals.description = goal.description;
        newGoal.goalsArray.push(newGoals);
        newGoalsArray.push(newGoal);
      } else {
        newGoals.gradeDesc = goal.gradeDesc;
        newGoals.description = goal.description;
        newGoalsArray[goalindex].goalsArray.push(newGoals);
      }
    });
    return newGoalsArray;
  }

  public goalsRenderAssig = () => {
    const { questionaryTeachingPathStore } = this.props;
    const { goals } = this.state;
    let classsubjectTitle = 'subjectTitle';
    if (this.transformGoalsAssig().length > 0) {
      this.transformGoalsAssig().forEach((e) => {
        if (e.goalsArray.length > 0) {
          e.goalsArray.forEach((ei) => {
            if (ei.gradeDesc!.length > NumberTen) {
              classsubjectTitle = 'subjectTitle subjectTitleCont';
            }
          });
        }
      });
    }
    return (
      <div className="modalContentTG__body__item">
        <h3><img className="imgInfo" src={goalsIcon} /> {intl.get('preview.teaching_path.grep.educational_goals')}</h3>
        <ul className="modalContentTG__body__listGoals">
          {/*tslint:disable-next-line:max-line-length*/}
          {this.transformGoalsAssig().map(item => <li key={item.subjectId}><div className={classsubjectTitle}>{item.subjectDesc}<div className="description"><ul>{item.goalsArray.map(li => <li key={item.subjectId} className="goalsItem"><div className="goalsItem__grade">{li.gradeDesc}</div><div className="goalsItem__description">{li.description}</div></li>)}</ul></div></div></li>)}
        </ul>
      </div>
    );
  }

  public contentDetailModal = () => {
    const { isTeachingPath, isAssignment } = this.props;
    return (
      <div className="modalContentTG open">
        <div className="modalContentTG__header">
          <h1>{intl.get('generals.viewdetails_title')}</h1>
          <button className="modalContentTG__header__close" onClick={this.closeModalDetail}>
            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="roundedCircle" fill-rule="evenodd" clip-rule="evenodd" d="M15.2476 0.9375C23.0138 0.9375 29.3101 7.23375 29.3101 15C29.3101 22.7663 23.0138 29.0625 15.2476 29.0625C7.48131 29.0625 1.18506 22.7663 1.18506 15C1.18506 7.23375 7.48131 0.9375 15.2476 0.9375Z" stroke="#0B2541" fill="white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.62256 20.625L20.8713 9.375" stroke="#0B2541" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.8726 20.625L9.62134 9.375" stroke="#0B2541" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <div className="modalContentTG__body">
          {isTeachingPath && this.subjectRender()}
          {isTeachingPath && this.coreElementsRender()}
          {isTeachingPath && this.multiSubjectsRender()}
          {isTeachingPath && this.goalsRender()}
          {isAssignment && this.subjectRenderAssig()}
          {isAssignment && this.coreElementsRenderAssig()}
          {isAssignment && this.multiSubjectsRenderAssig()}
          {isAssignment && this.goalsRenderAssig()}
        </div>
      </div>
    );
  }
  public backgroundDetailModal = () => {
    const { isOpen } = this.state;
    return (
      <div className="modalContentTGBackground" onClick={this.closeModalDetail} />
    );
  }
  public render() {
    const { } = this.props;
    return (
      <div className="detailsModal">
        {this.props.questionaryTeachingPathStore!.isOpenModalDetails && this.contentDetailModal()}
        {this.props.questionaryTeachingPathStore!.isOpenModalDetails && this.backgroundDetailModal()}
      </div>
    );
  }
}
