import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Animated } from 'react-animated-css';

import arrowLeftRounded from 'assets/images/arrow-left-rounded.svg';
import arrowLeftRoundedGray from 'assets/images/arrow-left-rounded-gray.svg';
import arrowRightRounded from 'assets/images/arrow-right-rounded.svg';
import arrowRightRoundedGray from 'assets/images/arrow-right-rounded-gray.svg';

import { AppHeader } from 'components/layout/AppHeader/AppHeader';
import { AssignmentOverview } from './AssignmentOverview/AssignmentOverview';
import { ArrowControls } from './ArrowControls/ArrowControls';
import { QuestionSimple } from 'assignment/Assignment';
import { AnswerCurrentQuestion } from './AnswerCurrentQuestion/AnswerCurrentQuestion';
import { CurrentQuestionaryStore } from './CurrentQuestionaryStore';
import { RedirectData } from '../../questionary/Questionary';
import { BreadcrumbsTeachingPath } from 'components/common/Breadcrumbs/BreadcrumbsComponent';
import { QuestionaryTeachingPathStore } from 'teachingPath/questionaryTeachingPath/questionaryTeachingPathStore';
import * as QueryStringHelper from 'utils/QueryStringHelper';
import { UIStore } from 'locales/UIStore';
import { Loader } from 'components/common/Loader/Loader';
import { Notification, NotificationTypes } from 'components/common/Notification/Notification';

import './CurrentAssignmentPage.scss';
import { AssignmentListStore } from '../AssignmentsList/AssignmentListStore';
import shared from 'assets/images/shared.svg';
import downloadImg from 'assets/images/download.svg';
import { TeacherGuidanceAssigModal } from '../TeacherGuidance/TeacherGuidanceAssigModal';
import { DetailsModal } from 'components/common/DetailsModal/DetailsModal';
import { ContentBlock } from 'assignment/ContentBlock';

const COVER_INDEX = -2;
const animationInDuration = 500;
const animationOutDuration = 300;
const limitSplit = 4;
const itemSplit = 3;
const downloadWait = 500;

export interface LocationState {
  teachingPath?: number;
  node?: number;
  withoutRefresh?: boolean;
  originPath?: string;
  title?: string;
  isTeachingPath?: boolean;
}

interface RouteParams {
  id?: string;
  answerId?: string;
}

enum ExitEventTarget {
  HEADER_LOGO,
  EXIT_BUTTON,
}

type LocataionProps = Location<{ readOnly: boolean } & LocationState>;

interface CurrentAssignmentPagePreviewProps extends RouteComponentProps<RouteParams, {}, LocationState> {
  currentQuestionaryStore: CurrentQuestionaryStore;
  questionaryTeachingPathStore?: QuestionaryTeachingPathStore;
  assignmentListStore?: AssignmentListStore;
  uiStore?: UIStore;
  location: LocataionProps;
  isTeacher?: boolean;
}

interface State {
  showCover: boolean;
}

enum QueryStringKeys {
  PAGE = 'page',
  QUESTION_ID = 'question',
}

export enum ContentType {
  COVER = 'cover',
  ARTICLE_LIST = 'articleList',
  QUESTION = 'question',
  SUBMIT = 'submit',
}

@inject('currentQuestionaryStore', 'questionaryTeachingPathStore', 'uiStore', 'assignmentListStore')
@observer
export class CurrentAssignmentPagePreview extends Component<CurrentAssignmentPagePreviewProps, State> {
  public state = {
    showCover: true,
  };

  public switchCover = () => {
    const { currentQuestionaryStore } = this.props;

    this.setState({ showCover: false }, () => {
      currentQuestionaryStore!.setStartedAssignment(true);

      if (currentQuestionaryStore!.currentQuestionary && currentQuestionaryStore!.currentQuestionary.redirectData) {
        currentQuestionaryStore!.setCurrentQuestion(0);
        return this.updateQueryString();
      }

      if (currentQuestionaryStore!.assignment!.relatedArticles.length > 0) {
        currentQuestionaryStore!.setCurrentQuestion(-1);
        return this.updateQueryString();
      }
      currentQuestionaryStore!.setCurrentQuestion(0);
      return this.updateQueryString();
    });
  }

  public goToNextQuestion = () => {
    const { setCurrentQuestion, currentQuestionIndex } = this.props.currentQuestionaryStore;
    setCurrentQuestion(currentQuestionIndex + 1);
    this.updateQueryString();
  }

  public goToPrevQuestion = () => {
    const { setCurrentQuestion, currentQuestionIndex } = this.props.currentQuestionaryStore;
    setCurrentQuestion(currentQuestionIndex - 1);
    this.updateQueryString();
  }
  public async componentDidMount() {
    const { currentQuestionaryStore, match, questionaryTeachingPathStore, isTeacher, history } = this.props;
    const headerArray = Array.from(document.getElementsByClassName('AppHeader') as HTMLCollectionOf<HTMLElement>);
    headerArray[0].style.display = 'none';
    const myid = (typeof match.params.id === 'number') ? match.params.id : Number(match.params.id!.split('?')[0]);
    const assignmentData = await currentQuestionaryStore.getQuestionaryById(Number(myid));
    questionaryTeachingPathStore!.contentAssignment = assignmentData.assignment;
    if (currentQuestionaryStore!.assignment && currentQuestionaryStore!.assignment!.relatedArticles && currentQuestionaryStore!.assignment!.relatedArticles.length > 0 && currentQuestionaryStore!.assignment!.relatedArticles[0].isHidden) {
      currentQuestionaryStore!.setCurrentQuestion(0);
      return this.updateQueryString();
      /* this.updateQueryString(); */

    }
    if (currentQuestionaryStore!.assignment!.relatedArticles! && currentQuestionaryStore!.assignment!.relatedArticles!.length === 0) {
      currentQuestionaryStore!.setCurrentQuestion(0);
      /* this.updateQueryString(); */
      return this.updateQueryString();
    }
    currentQuestionaryStore!.setCurrentQuestion(-1);
    await currentQuestionaryStore.getRelatedArticles();
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const myTg = searchParams.get('tg');
    if (Boolean(myTg)) {
      this.openModalTG();
    }
    return this.updateQueryString();
    /* return this.updateQueryString(); */

  }
  public openModalTG = () => {
    const modalTG = Array.from(document.getElementsByClassName('modalContentTGAssig') as HTMLCollectionOf<HTMLElement>);
    const modalTGBack = Array.from(document.getElementsByClassName('modalContentTGAssigBackground') as HTMLCollectionOf<HTMLElement>);
    if (modalTG) {
      modalTG[0].classList.add('open');
    }
    if (modalTGBack) {
      modalTGBack[0].classList.remove('hide');
    }
  }
  public async componentDidUpdate(prevProps: CurrentAssignmentPagePreviewProps) {
    const { currentQuestionaryStore, match, history } = this.props;
    const headerArray = Array.from(document.getElementsByClassName('AppHeader') as HTMLCollectionOf<HTMLElement>);
    headerArray[0].style.display = 'flex';
  }

  public handleExit = (exitEventTarget: ExitEventTarget) => async () => {
    const {
      questionaryTeachingPathStore,
      history,
      location: { state }
    } = this.props;
    const { teachingPath, node } = (history.location.state || {}) as RedirectData;

    const exitQuestionary = this.isReadOnly
      ? true
      : await Notification.create({
        type: NotificationTypes.CONFIRM,
        title: intl.get('current_assignment_page.exit_confirm')
      });
    if (teachingPath) {
      /* this.props.history.push(`/teaching-path/preview/${teachingPath}`, {
        node,
        teachingPath: -1
      }); */
      const confirmation = await Notification.create({
        type: NotificationTypes.CONFIRM,
        title: intl.get('current_assignment_page.exit_confirm')
      });
      if (confirmation) {
        this.props.history.push('/teaching-paths/all');
      }
    } else {
      if (exitQuestionary) {
        this.props.uiStore!.hideSidebar();
        if (state && state.node && state.teachingPath && questionaryTeachingPathStore!.currentNode) {
          questionaryTeachingPathStore!.handleAssignment(false);
          history.push(`/teaching-path/${state.teachingPath}`, {
            node: questionaryTeachingPathStore!.currentNode.id
          });
        } else {
          /* history.push('/assignments'); */
          const url: string = localStorage!.getItem('url') !== null ? localStorage!.getItem('url')!.toString().toString().split('?')[1] : '';
          this.props.history.push(`/assignments/all?${url}`);
          localStorage.removeItem('url');
          localStorage.clear();
        }
      } else {
        history.push('/assignments');
      }
    }

  }

  public updateQueryString() {
    const { currentQuestionaryStore, history } = this.props;
    const redirectData = (currentQuestionaryStore!.currentQuestionary && currentQuestionaryStore!.currentQuestionary.redirectData)
      ? currentQuestionaryStore!.currentQuestionary.redirectData
      : undefined;

    let currentPage;
    let questionId;
    if (this.state.showCover) {
      currentPage = ContentType.COVER;
      questionId = null;
    } else if (currentQuestionaryStore!.assignment && currentQuestionaryStore!.assignment!.relatedArticles.length > 0 && currentQuestionaryStore!.currentQuestionIndex < 0 && redirectData === undefined) {
      currentPage = ContentType.ARTICLE_LIST;
      questionId = null;
      if (currentQuestionaryStore!.assignment && currentQuestionaryStore!.assignment!.relatedArticles[0].isHidden) {
        currentQuestionaryStore!.setCurrentQuestion(0);
        currentPage = ContentType.QUESTION;
        questionId = currentQuestionaryStore.currentQuestionIndex;
      }
    } else if (currentQuestionaryStore.currentAnswer) {
      currentPage = ContentType.QUESTION;
      questionId = currentQuestionaryStore.currentQuestionIndex;
    } else {
      currentPage = ContentType.SUBMIT;
      questionId = null;
    }

    QueryStringHelper.set(history, QueryStringKeys.PAGE, currentPage);
    if (!isNil(questionId)) {
      QueryStringHelper.set(history, QueryStringKeys.QUESTION_ID, questionId);
    } else {
      QueryStringHelper.remove(history, QueryStringKeys.QUESTION_ID);
    }
  }

  public setCurrentQuestion = (index: number) => {
    this.props.currentQuestionaryStore!.setCurrentQuestion(index);
    this.updateQueryString();
  }

  public handlePublish = async () => {
    const { currentQuestionaryStore, history } = this.props;
    const { teachingPath, node } = (history.location.state || {}) as RedirectData;

    if (teachingPath) {
      this.props.history.push(`/teaching-path/preview/${teachingPath}`, {
        node
      });
    }
  }

  public donwloadPdf = async () => {
    const { currentQuestionaryStore, history } = this.props;
    const { teachingPath, node } = (history.location.state || {}) as RedirectData;
    const arraySend : Array<QuestionSimple> = [];
    const id = currentQuestionaryStore.currentQuestionary!.assignment.id;
    const title = currentQuestionaryStore.currentQuestionary!.assignment.title;
    const description = currentQuestionaryStore.currentQuestionary!.assignment.description;
    const locale = currentQuestionaryStore.currentQuestionary!.assignment.locale_id;
    currentQuestionaryStore.questions.forEach((question, index) => {
      let contentNew = '';
      if (question.content) {
        question.content.forEach((qe: ContentBlock) => {
          contentNew = contentNew + qe.text;
        });
      }
      const valueNew =  String(currentQuestionaryStore.answers[index].value).replace(/\n/g, '<br />');
      contentNew =  String(contentNew).replace(/\n/g, '<br />');
      const item = new QuestionSimple(question.title, contentNew, valueNew);
      arraySend.push(item);
    });
    const btnDownload = document.getElementById('btnDownloadAnswerPDFTP');
    btnDownload!.setAttribute('disabled', 'true');
    btnDownload!.classList.add('downloading');
    btnDownload!.firstChild!.textContent = `${intl.get('generals.downloading')} ...`;
    setTimeout(
      async () => {
        await currentQuestionaryStore!.downloadAnserdsPdf(arraySend, title, description, locale!);
        btnDownload!.removeAttribute('disabled');
        btnDownload!.classList.remove('downloading');
        btnDownload!.firstChild!.textContent = intl.get('teacherGuidance.download_pdf_answers');
      },
      downloadWait
    );
  }

  public finishPreviewSubmit = async () => {
    const { currentQuestionaryStore, history } = this.props;
    const { teachingPath, node } = (history.location.state || {}) as RedirectData;
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const myLocaleId = searchParams.get('localeId');

    if (teachingPath) {
      this.props.history.push(`/teaching-path/preview/${teachingPath}?localeId=${myLocaleId}`, {
        node
      });
    } else {
      const url: string = localStorage!.getItem('url') !== null ? localStorage!.getItem('url')!.toString().split('?')[1] : '';
      this.props.history.push(`/assignments/all?${url}`);
      localStorage.removeItem('url');
      localStorage.clear();

    }
  }

  public redirectToCurrentNode = (idTeachingPath: number, node: number) => {
    this.props.history.push({
      pathname: `/teaching-path/${idTeachingPath}`,
      state: { node, withoutRefresh: true }
    });
  }

  public get canGoToPrevQuestion() {
    const {
      currentQuestionaryStore: {
        currentQuestionIndex,
        isStartedAssignment,
        relatedArticles
      },
      isTeacher
    } = this.props;
    return ((isStartedAssignment || isTeacher) && currentQuestionIndex === 0 && relatedArticles.length > 0)
      || currentQuestionIndex > 0;
  }

  public get canGoToNextQuestion() {
    const {
      currentQuestionaryStore: {
        currentQuestionIndex,
        isStartedAssignment,
        questionTitlesListWithSubmit
      },
      isTeacher
    } = this.props;
    return currentQuestionIndex !== questionTitlesListWithSubmit.length - 1 && (isStartedAssignment || isTeacher);
  }

  public get isReadOnly(): boolean {
    const { location, isTeacher } = this.props;
    return !!((location && location.state && location.state.readOnly) || isTeacher);
  }

  public renderNextButton = () => {
    const classes = classNames('CurrentAssignmentPage__button CurrentAssignmentPage__button_right', {
      CurrentAssignmentPage__button_disabled: !this.canGoToNextQuestion,
    });

    return (
      <button
        className={classes}
        onClick={this.goToNextQuestion}
        disabled={!this.canGoToNextQuestion}
        title="Next"
      >
        <img
          className="CurrentAssignmentPage__buttonImage"
          src={this.canGoToNextQuestion ? arrowRightRounded : arrowRightRoundedGray}
          alt="arrow right"
          title="arrow right"
        />
      </button>
    );
  }

  public renderBackButton = () => {
    const classes = classNames('CurrentAssignmentPage__button', {
      CurrentAssignmentPage__button_disabled: !this.canGoToPrevQuestion,
    });

    return (
      <button
        className={classes}
        onClick={this.goToPrevQuestion}
        disabled={!this.canGoToPrevQuestion}
        title="Back"
      >
        <img
          className="CurrentAssignmentPage__buttonImage"
          src={this.canGoToPrevQuestion ? arrowLeftRounded : arrowLeftRoundedGray}
          alt="arrow left"
          title="arrow left"
        />
      </button>
    );
  }

  public renderNavigationIfNeeded = () => this.props.currentQuestionaryStore.isStartedAssignment && (
    <div className="CurrentAssignmentPage__navigation">
      {this.renderBackButton()}
      {this.renderNextButton()}
    </div>
  )

  public renderBreadcrumbsIfNeeded = () => (
    <div className="CurrentAssignmentPage__mybreadcrumbs">
      {this.renderBackButton()}
      <BreadcrumbsTeachingPath getCurrentNodeFromAssignment={this.redirectToCurrentNode} blockedtype/>
      {this.renderNextButton()}
    </div>
  )

  public renderSaveNotification = () => {
    const { currentQuestionary } = this.props.currentQuestionaryStore!;

    return currentQuestionary && currentQuestionary.isQuestionaryChanged && (
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={currentQuestionary && currentQuestionary.isQuestionarySaving}
        animationInDuration={animationInDuration}
        animationOutDuration={animationOutDuration}
        className="CurrentAssignmentPage__notification"
      >
        <div>
          {intl.get('current_assignment_page.saved_successfuly')}
        </div>
      </Animated>
    );
  }

  public renderIfneedNextButton() {
    return (
      <div className="ButtonNextNew">
        <button
          className="ButtonNextNew--btn"
          onClick={this.goToNextQuestion}
          disabled={!this.canGoToNextQuestion}
          title={intl.get('pagination.Next page')}
        >
          {intl.get('pagination.Next page')}
        </button>
      </div>
    );
  }

  public onClickSharedButton = () => {
    const { location } = this.props;
    const id = Number(location.pathname.split('/', limitSplit)[itemSplit]);
    const envUrl = `${String(process.env.REACT_APP_BASE_URL)}/web/open/assignments/${id}`;
    navigator.clipboard.writeText(String(envUrl));
    Notification.create({
      type: NotificationTypes.SUCCESS,
      title: intl.get('current_assignment_page.copy_link')
    });
  }

  public sharedButton = () => {
    const { location } = this.props;
    return (
      <div className="sharedFloatingButton">
        <a href="javascript:void(0);" onClick={this.onClickSharedButton} title={intl.get('login_page.Copy url')}>
          <img src={shared} />
        </a>
      </div>
    );
  }

  public onButtonDetailsClick = () => {
    const { questionaryTeachingPathStore } = this.props;
    questionaryTeachingPathStore!.isOpenModalDetails = true;
  }

  public render() {
    const {
      currentQuestionaryStore: {
        answers,
        currentQuestionIndex,
        currentAnswer,
        questionTitlesListWithSubmit,
        numberOfQuestions,
        assignment,
        numberOfAnsweredQuestions,
        isLoading,
        handleShowArrowsTooltip,
        getIsReadArticles,
        isStartedAssignment,
        isMultipleQuestion,
      },
      location: { state },
      uiStore,
      isTeacher,
      assignmentListStore,
      match,
      questionaryTeachingPathStore,
      history
    } = this.props;
    const { teachingPath } = (history.location.state || {}) as RedirectData;
    const isShowAssignmentArticles = !!(assignment && assignment!.relatedArticles.length > 0 && !assignment!.relatedArticles[0].isHidden);
    const isReadArticles = getIsReadArticles();
    toJS(this.props.currentQuestionaryStore); // VALUES OF ANSWERS WILL NOT WORK WITHOUT THIS STRING
    const navBarClasses = classNames('CurrentAssignmentPage__navBar', {
      CurrentAssignmentPage__navBar_visible: this.props.uiStore!.sidebarShown,
    });
    let isVisibleButtonRender = false;

    if (isLoading) {
      return <div className={'loading currenpathLoading'}><Loader /></div>;
    }
    if (currentAnswer) {
      isVisibleButtonRender = true;
    }
    const url: URL = new URL(window.location.href);
    const openTeacherGuidance: boolean = (url.searchParams.get('open') === 'tg' ? true : false);

    return !isLoading && (
      <div tabIndex={0} className="CurrentAssignmentPage CurrentAssigmentPreview">
        <AppHeader
          fromAssignmentPassing
          isPreview
          onLogoClick={this.handleExit(ExitEventTarget.HEADER_LOGO)}
          entityStore={assignmentListStore!}
          currentEntityId={Number(match.params.id)}
          onButtonDetailsClick={this.onButtonDetailsClick}
        />
        <DetailsModal isAssignment isOpenClick={questionaryTeachingPathStore!.isOpenModalDetails}/>
        <TeacherGuidanceAssigModal
          currentQuestionaryStore={this.props.currentQuestionaryStore}
          openGuidance={openTeacherGuidance}
          readOnly={true}
        />

        {this.props.uiStore!.sidebarShown && <div className="CurrentAssignmentPage__overlay" onClick={uiStore!.hideSidebar} />}

        <div className="CurrentAssignmentPage__content">
          <div className="CurrentAssignmentPage__container">
            <div className="CurrentAssignmentPage__main questionBody">
              <div className="CurrentAssignmentPage__main__center">
                <div className={navBarClasses}>
                  <AssignmentOverview
                    answers={answers}
                    isTeacher={isTeacher}
                    questionsList={questionTitlesListWithSubmit}
                    setCurrentQuestion={this.setCurrentQuestion}
                    currentQuestion={currentQuestionIndex}
                    numberOfAnsweredQuestions={numberOfAnsweredQuestions}
                    handleShowArrowsTooltip={handleShowArrowsTooltip}
                    readOnly={this.isReadOnly}
                    isShowAssignmentArticles={isShowAssignmentArticles}
                    isStartedAssignment={isStartedAssignment}
                    isReadArticles={isReadArticles}
                    redirectData={state && state.node}
                    isPreview
                  />
                </div>
                {this.renderBreadcrumbsIfNeeded()}
                <AnswerCurrentQuestion
                  answer={currentAnswer}
                  numberOfQuestions={numberOfQuestions}
                  numberOfAnsweredQuestions={numberOfAnsweredQuestions}
                  publishQuestionary={this.handlePublish}
                  finishPreviewSubmit={this.finishPreviewSubmit}
                  donwloadPdf={this.donwloadPdf}
                  readOnly={this.isReadOnly}
                  switchCover={this.switchCover}
                  showCover={this.state.showCover}
                  isTeachingPath={state && !!state.teachingPath}
                  isPreview
                  isIdTeachingPath={teachingPath}
                />
                {isVisibleButtonRender && this.renderIfneedNextButton()}
              </div>
            </div>
            {this.renderBreadcrumbsIfNeeded()}
          </div>
          {this.sharedButton()}
          {this.renderSaveNotification()}
        </div>
      </div>
    );
  }
}
