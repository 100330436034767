import { injector } from 'Injector';
import {
  AssignmentRepo,
  ArticleRepo,
  Article,
  Attachment,
  Filter,
  ASSIGNMENT_REPO,
  ARTICLE_REPO_KEY,
  WPLocale,
  QuestionSimple
} from './Assignment';
import { Locales } from 'utils/enums';

export const ASSIGNMENT_SERVICE = 'ASSIGNMENT_SERVICE';

export class AssignmentService {

  protected assignmentRepo: AssignmentRepo = injector.get<AssignmentRepo>(ASSIGNMENT_REPO);

  public async getGrades() {
    return this.assignmentRepo.getGrades();
  }

  public async getSubjects() {
    return this.assignmentRepo.getSubjects();
  }

  public async removeAssignment(assignmentId: number) {
    return this.assignmentRepo.removeAssignment(assignmentId);
  }

  public async getAssignmentById(assignmentId: number) {
    return this.assignmentRepo.getAssignmentById(assignmentId);
  }

  public async getAllAssignmentsList(filter: Filter) {
    return this.assignmentRepo.getAllAssignmentsList(filter);
  }

  public async getMyAssignmentsList(filter: Filter) {
    return this.assignmentRepo.getMyAssignmentsList(filter);
  }

  public async getStudentAssignmentList(filter: Filter) {
    return this.assignmentRepo.getStudentAssignmentList(filter);
  }

  public async getGrepFiltersAssignment(locale: string, grades: string, subjects: string, coreElements?: string, goals? : string, source?: string) {
    return this.assignmentRepo.getGrepFiltersAssignment(locale, grades, subjects, coreElements, goals, source);
  }

  public async getAssignmentListOfStudentInList(studentId: number, filter: Filter) {
    return this.assignmentRepo.getAssignmentListOfStudentInList(studentId, filter);
  }

  public async getAssignmentDistributes(filter: Filter) {
    return this.assignmentRepo.getAssignmentDistributes(filter);
  }

  public async copyAssignment(id: number) {
    return this.assignmentRepo.copyAssignment(id);
  }

  public async downloadTeacherGuidancePDF(id: number) {
    return this.assignmentRepo.downloadTeacherGuidancePDF(id);
  }
  public async downloadAnserdsPdf(answers: Array<QuestionSimple>, title: string, description: string, locale: number) {
    return this.assignmentRepo.downloadAnserdsPdf(answers, title, description, locale);
  }
}

export class ArticleService {
  private articleRepo: ArticleRepo = injector.get(ARTICLE_REPO_KEY);

  public async getArticles(params?: {
    page: number,
    perPage: number,
    order?: string,
    grades?: number,
    subjects?: number,
    core?: number | string,
    goal?: number | string,
    multi?: number,
    source?: number,
    searchTitle?: string
  }): Promise<Array<Article>> {
    return this.articleRepo.getArticles(params);
  }

  public async getArticlesByIds(ids: Array<number | false>): Promise<Array<Article>> {
    return this.articleRepo.getArticlesByIds(ids);
  }

  public async fetchVideos(postIds: Array<number>): Promise<Array<Attachment>> {
    return this.articleRepo.fetchVideos(postIds);
  }

  public async fetchImages(postIds: Array<number>): Promise<Array<Attachment>> {
    return this.articleRepo.fetchImages(postIds);
  }

  public async getLocaleData(locale: Locales): Promise<Array<WPLocale>> {
    return this.articleRepo.getLocaleData(locale);
  }
}
