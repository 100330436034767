import React, { Component, MouseEvent } from 'react';
import intl from 'react-intl-universal';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import { LoginStore } from 'user/view/LoginStore';
import { UserType } from 'user/User';
import { MyAccountWindowWrapper } from './MyAccountWindow/MyAccountWindow';
import { UIStore } from 'locales/UIStore';
import { CreateButton } from 'components/common/CreateButton/CreateButton';
import { Notification, NotificationTypes } from 'components/common/Notification/Notification';

import logoImage from 'assets/images/logo.svg';
import burger from 'assets/images/burger-icon.svg';
import verticalDots from 'assets/images/vertical-dots.svg';
import userPlaceholder from 'assets/images/user-placeholder.png';
import settingsIcon from 'assets/images/settings-icon.svg';
import question from 'assets/images/questions.svg';
import shared from 'assets/images/shared.svg';
import facebook from 'assets/images/facebook.svg';
import twitter from 'assets/images/twitter.svg';
import whatsapp from 'assets/images/whatsapp.svg';
import detailModalImg from 'assets/images/goals-white.svg';
import teaGuiBGImg from 'assets/images/guidance-bg.svg';

import './AppHeader.scss';
import { AssignmentListStore } from 'assignment/view/AssignmentsList/AssignmentListStore';
import { TeachingPathsListStore } from 'teachingPath/view/TeachingPathsList/TeachingPathsListStore';
import loginBtnIcon from 'assets/images/login-btn-icon.svg';

interface HeaderNavigationLink {
  name: string;
  url: string;
  dropdown?: boolean;
  submenuItems?: Array<HeaderNavigationLink>;
}

const LEFTCODE = 8592;
const RIGHTCODE = 8594;
const ANIMATION_TIMEOUT = 200;
const TwentySeven = 27;

const headerLinks: Array<HeaderNavigationLink> = [
  {
    name: 'Library',
    url: '#',
    dropdown: true,
    submenuItems: [
      {
        name: 'School Articles',
        url: `${process.env.REACT_APP_WP_URL}/undervisning/`
      }/* ,
      {
        name: 'Publications',
        url: `${process.env.REACT_APP_WP_URL}/temaboker/`
      },
      {
        name: 'Articles',
        url: `${process.env.REACT_APP_WP_URL}/artikler/`
      },
      {
        name: 'Sound articles',
        url: `${process.env.REACT_APP_WP_URL}/lydartikler/`
      }*/
    ]
  },
  {
    name: 'School',
    url: '#',
    dropdown: true,
    submenuItems: [
      {
        name: 'For foresatte',
        url: `${process.env.REACT_APP_WP_URL}/for-foresatte/`
      },
      {
        name: 'For Larere',
        url: '#',
        dropdown: true,
        submenuItems: [
          {
            name: 'Lesereisen',
            url: `${process.env.REACT_APP_WP_URL}/lesereisen-2022/`
          },
          {
            name: 'Arshjul',
            url: `${process.env.REACT_APP_WP_URL}/arshjul/`
          }
        ]
      }
    ]
  }
];
const tabletHeaderLinks: Array<HeaderNavigationLink> = [
  {
    name: 'School',
    url: `${process.env.REACT_APP_WP_URL}/undervisning/`
  },
  {
    name: 'Articles',
    url: `${process.env.REACT_APP_WP_URL}/artikler/`
  },
  {
    name: 'Publications',
    url: `${process.env.REACT_APP_WP_URL}/temaboker/`
  },
  {
    name: 'Sound articles',
    url: `${process.env.REACT_APP_WP_URL}/lydartikler/`
  },
  {
    name: 'About skolerom',
    url: `${process.env.REACT_APP_WP_URL}/om-skolerom/`
  }
];
const nynorskHeaderLinks: Array<HeaderNavigationLink> = [
  {
    name: 'School',
    url: `${process.env.REACT_APP_WP_URL}/nn/undervisning/`
  }
];

interface HeaderProps extends RouteComponentProps {
  entityStore?: AssignmentListStore | TeachingPathsListStore;
  loginStore?: LoginStore;
  fromAssignmentPassing?: boolean;
  fromTeachingPathPassing?: boolean;
  isPreview?: boolean;
  uiStore?: UIStore;
  width?:number;
  onLogoClick?: (e: MouseEvent) => void;
  onButtonDetailsClick?: () => void;
  currentEntityId?: number;
}

enum Modals {
  NONE,
  USER,
}

interface HeaderState {
  modalVisible: Modals;
  isModalKeyboard: boolean;
  isMobileModalOpen: boolean;
  linksMenu: Array<HeaderNavigationLink>;
  sharedbuttons: boolean;
}

@inject('loginStore', 'uiStore')
@observer
class AppHeader extends Component<HeaderProps, HeaderState> {
  private refCloseButton = React.createRef<HTMLButtonElement>();
  public readonly state: HeaderState = {
    modalVisible: Modals.NONE,
    isModalKeyboard: false,
    isMobileModalOpen: false,
    linksMenu: [],
    sharedbuttons: false
  };

  private renderUserModalIfNeeded() {
    return (
      <CSSTransition
        unmountOnExit
        in={this.state.modalVisible === Modals.USER}
        classNames="AppHeader__modal_animated"
        timeout={ANIMATION_TIMEOUT}
      >
        <MyAccountWindowWrapper
          navigation={tabletHeaderLinks}
          openKeyboardModal={this.openKeyboardModal}
          closeMyAccountWindow={this.closeModals}
          onLogIn={this.getFeideUrl}
        />
      </CSSTransition>
    );
  }

  private handleLogoClick = async (event: MouseEvent) => {
    const { uiStore, fromAssignmentPassing, fromTeachingPathPassing, onLogoClick } = this.props;
    if (fromAssignmentPassing || fromTeachingPathPassing) {
      event.preventDefault();

      onLogoClick!(event);
    }

    uiStore!.setCurrentActiveTab('teaching-paths');
  }

  private handleDetailsClick = () => {
    const { uiStore, fromAssignmentPassing, fromTeachingPathPassing, onButtonDetailsClick } = this.props;
    onButtonDetailsClick!();
  }

  private getFeideUrl = async () => {
    const { history } = this.props;

    history.push('dataporten/auth');
  }

  private renderAccountTab = () => {
    const { loginStore } = this.props;

    return (
      <li className="AppHeader__navigationItem AppHeader__settingsIconDiv" onClick={this.showUserModal}>
        <a href="javascript:void(0)" className="AppHeader__navigationItemText" title={intl.get('header.title.My account')}>
          <img
            className="AppHeader__settingsIcon"
            src={settingsIcon}
            aria-label="User Logo Description"
          />
        </a>
      </li>
    );
  }

  private renderRole = () => {
    const currentUser = this.props.loginStore!.currentUser;

    /*if (currentUser && currentUser.type === UserType.Teacher) {
      return intl.get('header.teacher');
    }
    if (currentUser && currentUser.type === UserType.Student) {
      return intl.get('header.student');
    }*/
    if (currentUser && currentUser.type === UserType.ContentManager) {
      return intl.get('header.content_manager');
    }
  }

  private showUserModal = () => {
    const { sidebarShown } = this.props.uiStore!;
    const { modalVisible } = this.state;

    if (sidebarShown) {
      this.props.uiStore!.hideSidebar();
    }
    if (modalVisible === Modals.NONE) {
      return this.setState({ modalVisible: Modals.USER });
    }
    if (modalVisible === Modals.USER) {
      return this.setState({ modalVisible: Modals.NONE });
    }
  }

  private closeModals = () => {
    this.setState({
      modalVisible: Modals.NONE,
    });
  }

  private toggleSidebar = () => {
    this.props.uiStore!.toggleSidebar();
    this.setState({ modalVisible: Modals.NONE });
  }

  private openKeyboardModal = () => {
    this.setState({ isModalKeyboard: true });
    if (this.refCloseButton.current) {
      this.refCloseButton.current.focus();
    }
  }

  private closeModalKeyboard = () => (this.setState({ isModalKeyboard: false }));

  private renderContentKeyboardStudent = () => (
    <div className="modalKeyboard__list">
      <h2>{intl.get('generals.accesibility_text.title_quick')}</h2>
      <ul>
        <li>
          <strong>Shift + C</strong>
          <p>{intl.get('generals.accesibility_text.shift_c')}</p>
        </li>
        <li>
          <strong>Shift + G</strong>
          <p>{intl.get('generals.accesibility_text.shift_g')}</p>
        </li>
        <li>
          <strong>Shift + A</strong>
          <p>{intl.get('generals.accesibility_text.shift_a')}</p>
        </li>
        <li>
          <strong>Shift + P</strong>
          <p>{intl.get('generals.accesibility_text.shift_p')}</p>
        </li>
        <li>
          <strong>Shift + D</strong>
          <p>{intl.get('generals.accesibility_text.shift_d')}</p>
        </li>
        <li>
          <strong>Shift + M</strong>
          <p>{intl.get('generals.accesibility_text.shift_m')}</p>
        </li>
        <li>
          <strong>Shift + S</strong>
          <p>{intl.get('generals.accesibility_text.shift_s')}</p>
        </li>
        <li>
          <strong>Shift + H</strong>
          <p>{intl.get('generals.accesibility_text.shift_h')}</p>
        </li>
      </ul>
    </div>
  )

  private renderContentKeyboardStudentTeacher = () => (
    <div className="modalKeyboard__list">
      <h2>{intl.get('generals.accesibility_text.title_quick')}</h2>
      <ul>
        <li>
          <strong>Shift + T</strong>
          <p>{intl.get('generals.accesibility_text.shift_t')}</p>
        </li>
        <li>
          <strong>Shift + N</strong>
          <p>{intl.get('generals.accesibility_text.shift_n')}</p>
        </li>
        <li>
          <strong>Shift + R</strong>
          <p>{intl.get('generals.accesibility_text.shift_r')}</p>
        </li>
        <li>
          <strong>Shift + U</strong>
          <p>{intl.get('generals.accesibility_text.shift_u')}</p>
        </li>
        <li>
          <strong>Shift + O</strong>
          <p>{intl.get('generals.accesibility_text.shift_o')}</p>
        </li>
        <li>
          <strong>Shift + F</strong>
          <p>{intl.get('assignment preview.Finish reading article')}</p>
        </li>
      </ul>
    </div>
  )

  private renderContentKeyboardTeacher = () => (
    <div className="modalKeyboard__list">
      <h2>{intl.get('generals.accesibility_text.title_when_editing')}</h2>
      <ul>
        <li>
          <strong>Shift + C</strong>
          <p>{intl.get('generals.accesibility_text.shift_c')}</p>
        </li>
        <li>
          <strong>Shift + G</strong>
          <p>{intl.get('generals.accesibility_text.shift_g')}</p>
        </li>
        <li>
          <strong>Shift + A</strong>
          <p>{intl.get('generals.accesibility_text.shift_a')}</p>
        </li>
        <li>
          <strong>Shift + S</strong>
          <p>{intl.get('generals.accesibility_text.shift_s')}</p>
        </li>
        <li>
          <strong>Shift + P</strong>
          <p>{intl.get('generals.accesibility_text.shift_p')}</p>
        </li>
        <li>
          <strong>Shift + D</strong>
          <p>{intl.get('generals.accesibility_text.shift_d')}</p>
        </li>
      </ul>
    </div>
  )

  private renderKeyboardModal = () => {
    const currentUser = this.props.loginStore!.currentUser;
    return (
      <div className="modalKeyboard">
        <div className="modalKeyboard__background" onClick={this.closeModalKeyboard} />
        <div className="modalKeyboard__content">
          <button ref={this.refCloseButton} className="modalKeyboard__close" onClick={this.closeModalKeyboard} />
          <div className="modalKeyboard__inside">
            {this.renderContentKeyboardStudent()}
          </div>
        </div>
      </div>
    );
  }

  private dropDownKeyboard = () => (
    <li className="AppHeader__dropdownItem">
      <a href="javascript:void(0)" onClick={this.openKeyboardModal} title={intl.get('header.title.keyboard')}>{intl.get('generals.keyboard')}</a>
    </li>
  )

  private renderQuestionTab = () => (
    <li className="AppHeader__navigationItem tc1 fs17 fw500">
      <div className="AppHeader__navigationItemText">
        <a href="javascript:void(0)" className="AppHeader__dropdown" title={intl.get('header.About')}>
          {intl.get('header.About')}
        </a>
        <div className="AppHeader__submenuWrapper">
          <ul className="AppHeader__submenu">
            <li className="AppHeader__dropdownItem">
              <a href={`${process.env.REACT_APP_WP_URL}/hva-er-skolerom`} title={intl.get('header.About skolerom')} target="_blank">{intl.get('header.About skolerom')}</a>
            </li>
            <li className="AppHeader__dropdownItem">
              <a href={`${process.env.REACT_APP_WP_URL}/support-skolerom`} title={intl.get('generals.support')} target="_blank">{intl.get('generals.support')}</a>
            </li>
            <li className="AppHeader__dropdownItem">
              <a href={`${process.env.REACT_APP_WP_URL}/kontakt-oss`} title={intl.get('header.contact')} target="_blank">{intl.get('header.contact')}</a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  )

  public handleKeyboardControl = (event: KeyboardEvent) => {
    const classDivPath = (event.composedPath()[0] as Element).className;
    const htmlPathArea = String(event.composedPath()[0]);
    const htmlText = '[object HTMLTextAreaElement]';
    const inputText = '[object HTMLInputElement]';
    const qlEditorText = 'ql-editor';
    const navigationItem = Array.from(document.getElementsByClassName('AppHeader__navigationItem') as HTMLCollectionOf<HTMLElement>);
    if (event.key === 'Escape') {
      this.setState({
        modalVisible: Modals.NONE,
      });
      this.setState({ isModalKeyboard: false });
    }
    if (htmlPathArea !== htmlText && htmlPathArea !== inputText && classDivPath !== qlEditorText) {
      if ((event.shiftKey && event.key === 'M') || (event.shiftKey && event.key === 'm')) {
        const dropDown = navigationItem[0].querySelector('.AppHeader__dropdown') as HTMLElement;
        dropDown!.focus();
      }
      if ((event.shiftKey && event.key === 'R') || (event.shiftKey && event.key === 'r')) {
        window.open(`${process.env.REACT_APP_WP_URL}/artikler/`, '_blank');
      }
      if ((event.shiftKey && event.key === 'U') || (event.shiftKey && event.key === 'u')) {
        window.open(`${process.env.REACT_APP_WP_URL}/temaboker/`, '_blank');
      }
      if ((event.shiftKey && event.key === 'O') || (event.shiftKey && event.key === 'o')) {
        window.open(`${process.env.REACT_APP_WP_URL}/lydartikler/`, '_blank');
      }
    }
  }

  public sendTitleActivity = () => {
    const currentUser = this.props.loginStore!.currentUser;
    if (currentUser && currentUser.type === UserType.Student) {
      return (
        <li className="AppHeader__navigationItem tc1 fs17 fw500 ">
          <div className="AppHeader__navigationItemText">
            <a href="/activity" className="permanActive" title={intl.get('header.student_room')}>{intl.get('header.student_room')}</a>
          </div>
        </li>
      );
    }
    if (currentUser && currentUser.type === UserType.Teacher) {
      return (
        <li className="AppHeader__navigationItem tc1 fs17 fw500 ">
          <div className="AppHeader__navigationItemText">
            <a href="/activity" className="permanActive" title={intl.get('header.teacher_room')}>{intl.get('header.teacher_room')}</a>
          </div>
        </li>
      );
    }
    if (currentUser && currentUser.type === UserType.ContentManager) {
      return (
        <li className="AppHeader__navigationItem tc1 fs17 fw500 ">
          <div className="AppHeader__navigationItemText">
            <a href="/activity" className="permanActive" title={intl.get('header.teacher_room')}>{intl.get('header.teacher_room')}</a>
          </div>
        </li>
      );
    }
  }

  public async componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboardControl);
    this.getdataMenu();
  }
  public componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboardControl);
  }

  public getdataMenu = async () => {
    const { uiStore } = this.props;
    const linksLis2t = await this.props.loginStore!.getMenuData(uiStore!.currentLocale);
    this.setState({
      linksMenu: linksLis2t
    });
  }

  public renderHeaderLink = (link: HeaderNavigationLink, index: number) => {
    const validIndex = (index === (this.state.linksMenu.length - 1)) ? true : false;
    if (link.dropdown) {
      const renderSubMenuSubMenu = (item: HeaderNavigationLink) => (
        <li key={item.name} className={'AppHeader__dropdownItem__subItem'}>
          <a href={item.url} title={item.name} role="button">{item.name}</a>
        </li>
      );
      const renderSubmenu = (item: HeaderNavigationLink) => {
        if (item.dropdown) {
          let maxLength = 0;
          if (item.submenuItems) {
            item.submenuItems.forEach((element) => {
              if (element.name.length > maxLength) {
                maxLength = element.name.length;
              }
            });
          }
          const classInside = (maxLength > TwentySeven) ? 'AppHeader__dropdownItem__subMenu subMenuItemActive' : 'AppHeader__dropdownItem__subMenu';
          return (
            <li key={item.name} className={'AppHeader__dropdownItem'}>
              <a href={item.url} title={item.name} role="button">{item.name}</a>
              <ul className={classInside}>
                {item.submenuItems!.map(renderSubMenuSubMenu)}
              </ul>
            </li>
          );
        }
        return (
          <li key={item.name} className={'AppHeader__dropdownItem'}>
            <a href={item.url} title={item.name}  role="button">{item.name}</a>
          </li>
        );
      };
      return (
        <li key={link.name} className="AppHeader__navigationItem tc1 fs17 fw500">
          <div className="AppHeader__navigationItemText">
            <a href={link.url} className="AppHeader__dropdown" title={link.name} role="button">{link.name}</a>
            <div className={'AppHeader__submenuWrapper'}>
              <ul className={'AppHeader__submenu'}>
                {link.submenuItems!.map(renderSubmenu)}
                {validIndex && this.dropDownKeyboard()}
              </ul>
            </div>
          </div>
        </li>
      );
    }
    return (
      <li key={link.name} className="AppHeader__navigationItem tc1 fs17 fw500">
        <div className="AppHeader__navigationItemText">
          <a href={link.url} title={link.name} role="button">{link.name}</a>
        </div>
      </li>
    );
  }

  public renderNavigation = () => {
    const { uiStore } = this.props;
    // const linksList = uiStore!.currentLocale === 'nn' ? nynorskHeaderLinks : headerLinks;
    const tabletLinksList = uiStore!.currentLocale === 'nn' ? nynorskHeaderLinks : headerLinks;
    const linksList = this.state.linksMenu;

    return (
      <>
        <ul className="AppHeader__navigation">
          {this.sendTitleActivity()}
          {linksList.map(this.renderHeaderLink)}
          {this.renderAccountTab()}
        </ul>
        <ul className="AppHeader__navigation AppHeader__navigation_tablet">
          {linksList.map(this.renderHeaderLink)}
          {this.renderAccountTab()}
        </ul>
      </>
    );
  }

  public handleCopy = async () => {
    const { entityStore, currentEntityId, history } = this.props;

    const isCopyApproved = await Notification.create({
      type: NotificationTypes.CONFIRM,
      title: intl.get('assignment list.Are you sure'),
      submitButtonTitle: intl.get('notifications.copy')
    });

    if (isCopyApproved) {
      const currentEntityRoute = entityStore instanceof AssignmentListStore ? 'assignments' : 'teaching-paths';
      const copyId = await entityStore!.copyEntity(currentEntityId!);
      history.push(`/${currentEntityRoute}/edit/${copyId}`);
    }
  }

  public renderCopyButton = (intlKey: string) => (
    <div className="doneBox flexBox alignCenter">
      <CreateButton
        onClick={this.handleCopy}
        title={intl.get(intlKey)}
      >
        {intl.get(intlKey)}
      </CreateButton>
    </div>
  )

  public openModalTGAssig = (nroLevel: string) => {
    const modalTG = Array.from(document.getElementsByClassName('modalContentTGAssig') as HTMLCollectionOf<HTMLElement>);
    const modalTGBack = Array.from(document.getElementsByClassName('modalContentTGAssigBackground') as HTMLCollectionOf<HTMLElement>);
    modalTG[0].classList.add('open');
    modalTGBack[0].classList.remove('hide');
  }

  public openModalTGTeachingPath = (nroLevel: string) => {
    const modalTG = Array.from(document.getElementsByClassName('modalContentTG') as HTMLCollectionOf<HTMLElement>);
    const modalTGBack = Array.from(document.getElementsByClassName('modalContentTGBackground') as HTMLCollectionOf<HTMLElement>);
    modalTG[0].classList.add('open');
    modalTGBack[0].classList.remove('hide');
  }

  public toggleSharedbuttons = () => {
    if (this.state.sharedbuttons) {
      this.setState({ sharedbuttons: false });
    } else {
      this.setState({ sharedbuttons: true });
    }
  }

  public sharedbuttons = () => {
    const { history } = this.props;
    const mylink = `${window.location.origin.toString()}${history.location.pathname}`;
    const sharedwhatsapp = `/whatsapp://send?text=${intl.get('header.whatsapp_shared', { link: mylink })}`;
    const sharedFacebook = `https://www.facebook.com/sharer/sharer.php?u=${mylink}`;
    const sharedTwitter = `http://twitter.com/share?text=${intl.get('header.twitter_shared', { link: mylink })}&url=${mylink}`;

    const classlist = (this.state.sharedbuttons) ? 'mySharedbutton__list active' : 'mySharedbutton__list';
    return (
      <div className="mySharedbutton">
        <a href="javascript:void(0)" className="mySharedbutton__init" onClick={this.toggleSharedbuttons}>
          <img src={shared} />
        </a>
        <div className={classlist} >
          <ul>
            <li>
              <a href={sharedwhatsapp} data-action="share/whatsapp/share" className="whatsapp_shared" target="_blank">
                <img src={whatsapp} />
              </a>
            </li>
            <li>
              <a href={sharedFacebook} className="facebook_shared" target="_blank">
                <img src={facebook} />
              </a>
            </li>
            <li>
              <a href={sharedTwitter} className="twitter_shared" target="_blank">
                <img src={twitter} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  public renderGuidanceAssigButton = () => (
    <div className="doneBox flexBox alignCenter">
      <CreateButton
        className="jr-btnHeaderTeacherGuidance AppHeader__btnHeaderGuidance copyInFlex copyNotInvert"
        onClick={this.openModalTGAssig.bind(this, '0')}
        title={intl.get('teacherGuidance.buttons.read')}
      >
        <img src={teaGuiBGImg} />
        {intl.get('teacherGuidance.buttons.read')}
      </CreateButton>
    </div>
  )

  public renderGuidanceTeachingPathButton = () => (
    <div className="doneBox flexBox alignCenter">
      <CreateButton
        className="jr-btnHeaderTeacherGuidance AppHeader__btnHeaderGuidance copyInFlex copyNotInvert"
        onClick={this.openModalTGTeachingPath.bind(this, '0')}
        title={intl.get('teacherGuidance.buttons.read')}
      >
        <img src={teaGuiBGImg} />
        {intl.get('teacherGuidance.buttons.read')}
      </CreateButton>
    </div>
  )

  public renderBurgerButton = () => {
    const { loginStore } = this.props;

    if (loginStore!.currentUser) {
      return (
        <button
          className="AppHeader__block AppHeader__button AppHeader__block_mobile"
          onClick={this.toggleSidebar}
          title="menu button"
        >
          <img src={burger} alt="burger button" title="menu button"/>
        </button>
      );
    }
  }

  public showMobileModal = () => {
    if (!this.state.isMobileModalOpen) {
      this.setState({ isMobileModalOpen: true });
    } else {
      this.setState({ isMobileModalOpen: false });
    }
  }

  public renderMobileButton = () => {
    if (this.props.loginStore!.currentUser) {
      return (
        <button
          className="AppHeader__block AppHeader__button AppHeader__block_mobile AppHeader__userMenuButton"
          onClick={this.showUserModal}
          title="user menu"
        >
          <img src={verticalDots} alt="user menu"/>
        </button>
      );
    }
    return (
      <button
        className="AppHeader__block AppHeader__button AppHeader__block_mobile AppHeader__userMenuButton"
        onClick={this.showMobileModal}
        title="user menu"
      >
        <img src={verticalDots} alt="user menu"/>
      </button>
    );
  }

  public renderItemsNotLogin = () => (
    <ul className="singleListElements">
      <li>
        <a href={`${process.env.REACT_APP_WP_URL}/kontakt-oss/`}>{intl.get('header.contact_menu')}</a>
      </li>
      <li>
        <a href={`${process.env.REACT_APP_WP_URL}/hva-er-skolerom-no/`}>{intl.get('header.about_menu')}</a>
      </li>
      <li>
        <a href={`${process.env.REACT_APP_BASE_URL}/api/dataporten/auth`} className="BtnFinal">
          <img src={loginBtnIcon} />
          <p>{intl.get('header.logg_in')}</p>
        </a>
      </li>
    </ul>
  )

  public renderLineMobileButton = () => (
    <div className="singleFlexElements AppHeader__block">
      {this.renderItemsNotLogin()}
    </div>
  )

  public renderModalMobileButton = () => (
    <div className="singleElements">
      {this.renderItemsNotLogin()}
    </div>
  )

  public buttonOpenDetailsModal = () => {
    const { loginStore, fromAssignmentPassing, fromTeachingPathPassing } = this.props;
    return (
      <CreateButton className="copyInFlex" title={intl.get('generals.viewdetails')} onClick={this.handleDetailsClick}>
        <img src={detailModalImg} alt={intl.get('generals.viewdetails')} />
        {intl.get('generals.viewdetails')}
      </CreateButton>
    );
  }

  public busttonsGuidanceAndDetails = () => {
    const { loginStore, fromAssignmentPassing, fromTeachingPathPassing } = this.props;
    return (
      <div className="ButtonsAddProp">
        {fromAssignmentPassing && this.renderGuidanceAssigButton()}
        {fromTeachingPathPassing && this.renderGuidanceTeachingPathButton()}
        {this.buttonOpenDetailsModal()}
      </div>
    );
  }

  public render() {
    const { loginStore, fromAssignmentPassing, fromTeachingPathPassing } = this.props;
    const currentUser = loginStore!.currentUser;
    const isStudent = loginStore!.currentUser ? loginStore!.currentUser.type === UserType.Student : false;
    const redirectLink = currentUser
        ? '/teaching-paths'
      : '#';

    let ifLogin = true;
    if (this.props.loginStore!.currentUser) {
      ifLogin = false;
    }

    return (
      <header className="AppHeader">
        {this.renderUserModalIfNeeded()}
        {this.state.modalVisible !== Modals.NONE && <div className="AppHeader__headerOverlay" onClick={this.closeModals} />}
        {!fromAssignmentPassing && !fromTeachingPathPassing && <p id="LogoDescription" className="hidden">Logo Skolerom</p>}
        {fromAssignmentPassing && fromTeachingPathPassing && <p id="LogoDescriptionStudent" className="hidden">Logo Skolerom</p>}
        <div className="AppHeader__block" aria-labelledby="LogoDescription">
          <NavLink to={redirectLink} onClick={this.handleLogoClick}>
            <div className="AppHeader__block">
              <img src={logoImage} alt="Skolerom Logo" className="AppHeader__logo" title="Skolerom"/>
              <span className="AppHeader__role">{this.renderRole()}</span>
            </div>
          </NavLink>
        </div>
        {!fromAssignmentPassing && !fromTeachingPathPassing && this.renderNavigation()}
        {this.props.isPreview && this.busttonsGuidanceAndDetails()}
        {this.renderBurgerButton()}
        <div className="AppHeader__block AppHeader__block_mobile">
          <NavLink to={redirectLink} onClick={this.handleLogoClick}>
            <img src={logoImage} alt="logo mobile"/>
          </NavLink>
        </div>
        <div className={'AppHeader__block_mobile AppHeader__block'}>
          {this.renderQuestionTab()}
          {this.renderMobileButton()}
          {this.state.isMobileModalOpen && this.renderModalMobileButton()}
        </div>
        {this.state.isModalKeyboard && this.renderKeyboardModal()}
      </header>
    );
  }
}

const resizeComponent = withRouter(AppHeader);
export { resizeComponent as AppHeader };
