import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import intl from 'react-intl-universal';
import { DescriptionEditor } from 'assignment/view/NewAssignment/Questions/DescriptionEditor';
import { DraftTeachingPath, EditableTeachingPathNode } from 'teachingPath/teachingPathDraft/TeachingPathDraft';
import { ItemContentTypeContext } from 'teachingPath/view/EditTeachingPath/ItemContentTypeContext';
import 'teachingPath/view/TeacherGuidance/TeacherGuidanceModal.scss';
import closeImg from 'assets/images/modal-close.svg';
import downloadImg from 'assets/images/download.svg';
import openTGImg from 'assets/images/open.svg';
import { EditTeachingPathStore } from 'teachingPath/view/EditTeachingPath/EditTeachingPathStore';
import { Link } from 'react-router-dom';
import { TeachingPathNodeType } from 'teachingPath/TeachingPath';
import { CreateButton } from 'components/common/CreateButton/CreateButton';

interface Props {
  editTeachingPathStore?: EditTeachingPathStore;
  currentEntity: DraftTeachingPath;
  readOnly?: boolean;
}

interface Teacherguidance {
  nroLevel: number;
  nroLetter: number;
  hideBorderTop: boolean;
  nroChild: number;
  children: EditableTeachingPathNode;
  hasAssignmenet: boolean;
}

@inject('editTeachingPathStore')
@observer
export class TeacherguidanceModal extends Component<Props> {
  public static contextType = ItemContentTypeContext;

  public openModalTG = (nroLevel: string) => {
    const { currentEntity } = this.props;
    currentEntity.handleOpenTeacherGuidance(nroLevel);
  }

  public closeModalTG = () => {
    const modalTG = Array.from(document.getElementsByClassName('modalContentTG') as HTMLCollectionOf<HTMLElement>);
    const modalTGBack = Array.from(document.getElementsByClassName('modalContentTGBackground') as HTMLCollectionOf<HTMLElement>);
    modalTG[0].classList.remove('open');
    modalTGBack[0].classList.add('hide');
  }

  public renderFirstNode = () => {
    const { currentEntity, readOnly } = this.props;
    const titleTG = currentEntity.content.selectQuestion;

    const hasAssignmenet = (currentEntity.content.children.length > 0 && currentEntity.content.children[0].type === TeachingPathNodeType.Assignment);
    let itemTG: Teacherguidance | null = null;

    if (hasAssignmenet) {
      itemTG = {
        nroLevel: 1,
        nroLetter: 1,
        hideBorderTop: false,
        nroChild: currentEntity.content.children.length,
        children: currentEntity.content,
        hasAssignmenet: true
      };
    }

    if (currentEntity.content.children.length > 0) {
      return (
        <div className="modalContentTG__body__row line">
          <h4>
            <div className="nestedOrderNumber">1</div>
            {titleTG}
          </h4>
          <DescriptionEditor
            className="jr-desEdit1a"
            description={currentEntity.content.guidance}
            readOnly={readOnly}
          />
          {hasAssignmenet && <div className="contentAssigments">{this.getContentAssignment(itemTG!)}</div>}
        </div>
      );
    }
  }

  public getContentAssignment = (itemTG: Teacherguidance) => {
    const countChildren = itemTG.children.children.length;
    if (countChildren > 0) {
      if (itemTG.children.children[0].type ===  TeachingPathNodeType.Assignment) {
        const listItemAssignment: Array<any> = [];
        itemTG.children.children.forEach((child) => {
          child.items!.forEach((item) => {
            listItemAssignment.push(item);
          });
        });

        return listItemAssignment!.map((itemAssignment: any, index) => (
          <Link key={index} to={`/assignments/preview/${itemAssignment.value.id}?preview${itemAssignment.value.hasGuidance ? '&open=tg' : ''}`} target="_blank">
            {itemAssignment.value.hasGuidance ? `${itemAssignment.value.title} — ${intl.get('teacherGuidance.name')}` : `${itemAssignment.value.title} — (${intl.get('teacherGuidance.noAdded')})`}
            {itemAssignment.value.hasGuidance ? <img src={openTGImg} alt={intl.get('teacherGuidance.nane')} /> : ''}
          </Link>
        ));
      }
    } else {
      return null;
    }
  }

  public renderChildrenNode = () => {
    const { currentEntity, readOnly } = this.props;
    let nroLevelLoop: number = 2;
    const firstLetterNumber: number = 97;

    const childrenFinal: Array<Teacherguidance> = [];
    let children: Array<EditableTeachingPathNode> = [];
    let childrenTmp: Array<EditableTeachingPathNode> = currentEntity.content.children;
    let nroNodes: number = 0;
    let nroLetterLoop: number = firstLetterNumber;
    let hideBorderTopLoop: boolean = true;
    let continueLoop = true;

    while (continueLoop) {
      nroNodes = 0;
      children = childrenTmp;
      childrenTmp = [];

      children.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((child) => {
            if (child.children.length > 0) {
              childrenTmp.push(child);
            }
          });

          const child:Teacherguidance = {
            nroLevel: nroLevelLoop,
            nroLetter: nroLetterLoop,
            hideBorderTop: hideBorderTopLoop,
            nroChild: children.length,
            children: item,
            hasAssignmenet: (item.children.length > 0 && item.children[0].type === TeachingPathNodeType.Assignment)
          };

          childrenFinal.push(child);
          nroLetterLoop += 1;
          nroNodes += 1;
          hideBorderTopLoop = false;
        }
      });

      nroLevelLoop += 1;
      nroLetterLoop = firstLetterNumber;
      hideBorderTopLoop = true;
      continueLoop = (nroNodes > 0);
    }

    return childrenFinal.map((item, index) => (
            <div className={`modalContentTG__body__row ${item.hideBorderTop ? 'line' : ''}`} key={index}>
              <h4>
                <div className="nestedOrderNumber">{item.nroLevel}</div>
                {item.children.selectQuestion} {item.nroChild > 1 ? `(${intl.get('teacherGuidance.option')} ${String.fromCharCode(item.nroLetter)})` : ''}
              </h4>
              <DescriptionEditor
                className={`jr-desEdit${item.nroLevel}${String.fromCharCode(item.nroLetter)}`}
                description={item.children.guidance}
                readOnly={readOnly}
              />
              {item.hasAssignmenet && <div className="contentAssigments">{this.getContentAssignment(item)}</div>}
            </div>
          )
        );
  }

  public renderFooterButtons = () => (
    <div className="modalContentTG__footer__aligLeft">
      <CreateButton
        title={intl.get('generals.save')}
        onClick={this.closeModalTG}
        green={true}
      >
        {intl.get('generals.save')}
      </CreateButton>
    </div>
  )

  public handleDownloadAsPDF = async () => {
    const { editTeachingPathStore, currentEntity, readOnly } = this.props;
    let downloadWait = 2000;
    if (readOnly) downloadWait = 0;

    const btnDownload = document.getElementById('btnDownloadPDFTP');
    btnDownload!.setAttribute('disabled', 'true');
    btnDownload!.classList.add('downloading');
    btnDownload!.firstChild!.textContent = `${intl.get('generals.downloading')} ...`;

    setTimeout(
      async () => {
        await editTeachingPathStore!.downloadTeacherGuidancePDF(currentEntity.id);
        btnDownload!.removeAttribute('disabled');
        btnDownload!.classList.remove('downloading');
        btnDownload!.firstChild!.textContent = intl.get('teacherGuidance.download_pdf');
      },
      downloadWait
    );
  }

  public render() {
    const { currentEntity, readOnly } = this.props;

    if (currentEntity === undefined) {
      return null;
    }

    if (currentEntity !== undefined) {
      if (currentEntity.hasGuidance) {
        const btnHeader = Array.from(document.getElementsByClassName('jr-btnHeaderTeacherGuidance') as HTMLCollectionOf<HTMLElement>);
        btnHeader[0].classList.remove('AppHeader__btnHeaderGuidance');
      }
    }

    const nroChildren: number = currentEntity.content.children.length;
    let titleTG = intl.get('teacherGuidance.titleEdit');
    let titleTGSub = intl.get('teacherGuidance.titleSub');

    if (nroChildren === 0) titleTG = intl.get('teacherGuidance.titleAdd');
    if (readOnly === true) {
      titleTG = intl.get('teacherGuidance.titleRead');
      titleTGSub = intl.get('teacherGuidance.titleSubRead');
    }

    return (
      <div>
        <div className="modalContentTG">
          <div className="modalContentTG__header">
            <h1>{titleTG}</h1>
            <span>{titleTGSub}</span>
            <button className="modalContentTG__header__close" onClick={this.closeModalTG}>
              <img
                src={closeImg}
                alt={intl.get('generals.close')}
                title={intl.get('generals.close')}
              />
            </button>
          </div>
          <div className="modalContentTG__body">
            <div className="modalContentTG__body__row first">
              <DescriptionEditor
                className="jr-desEdit0"
                description={currentEntity.guidance}
                readOnly={readOnly}
              />
            </div>
            {this.renderFirstNode()}
            {this.renderChildrenNode()}
          </div>
          <div className="modalContentTG__footer">
            {readOnly !== true && this.renderFooterButtons()}
            <div className="modalContentTG__footer__aligRight">
              <button id="btnDownloadPDFTP" onClick={this.handleDownloadAsPDF}>
                {intl.get('teacherGuidance.download_pdf')}
                <img src={downloadImg} />
              </button>
            </div>
          </div>
        </div>
        <div className="modalContentTGBackground hide">&nbsp;</div>
      </div>
    );
  }
}
